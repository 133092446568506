/**
 * mntyjs 1.0.0
 *
 * @preserve Copyright 2015 bitExpert AG
 */

require(['mntyjs/src/PluginManager'], function (PluginManager) {
    var Loader = PluginManager.extend({
            name: 'ElnVehicles',
            config: {
                loggingEnabled: window.isDev,
                mountPoint: 'mount-eln-vehicles',
                baseUrl: 'typo3conf/ext',
                loadFrom: 'eln_vehicles/Plugins'
            },

            load: function () {
                this.mount(document);
            }
        }),
        loader = new Loader;

    loader.load();
});
